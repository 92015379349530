<!--授权小程序-->
<template>
  <div class="we-chat">

    <Steps class="we-chat-steps" :active="active">
      <Step>
        <span class="slot-step" slot="icon">
          <b class="step-icon">1</b>
          注册小程序
        </span>
      </Step>
      <Step>
        <span class="slot-step" slot="icon">
          <b class="step-icon">2</b>
          授权小程序
        </span>
      </Step>
      <Step>
        <span class="slot-step" slot="icon">
          <b class="step-icon">3</b>
          完成绑定
        </span>
      </Step>
    </Steps>

    <div v-if="active === 1" class="register">
      <p class="required-mini">请先授权小程序</p>
      <p class="des">没有小程序? <Link class="auth-link" type="primary" @click="createWeChat">立即注册</Link></p>
      <el-button class="create-btn" type="primary" @click="authMiniApp">立即授权</el-button>
    </div>

    <div class="auth-content" v-else-if="active === 2">

      <div class="auth-item">
        <img class="auth-code" :src="authQrCode" alt="">
        <p>1. 请使用管理员微信扫码</p>
      </div>

      <div class="auth-item">
        <img class="auth-phone" src="../../assets/img/auth-0.png" alt="">
        <p>2. 选择要授权的小程序</p>
      </div>

      <div class="auth-item">
        <img class="auth-phone" src="../../assets/img/auto-02.png" alt="">
        <p>3. 授权要绑定的小程序</p>
      </div>

    </div>

    <div v-if="active === 3" class="auth-success">

      <img :src="authInfo.mpLogo" class="we-chat-icon" />

      <p class="title">{{ authInfo.mpName }}</p>

      <div class="global-flex">
        <span class="global-flex-label" style="width: 74px;">AppID:</span>
        <span class="global-flex-value">{{ authInfo.mpAppId }}</span>
        <div class="un-bind" size="mini" type="danger" @click="unBind">解绑</div>
      </div>

      <div class="global-flex">
        <span class="global-flex-label" style="width: 74px;">绑定时间:</span>
        <span class="global-flex-value">{{ authInfo.createTime }}</span>
      </div>

      <img :src="authInfo.qrCodeUrl" class="qrCode" />

      <p v-if="!authInfo.isPublish" class="no-pub">未发布, 请联系管理发布小程序</p>

    </div>

    <p class="public-des">注册小程序 -> 授权小程序 -> 提交代码 -> 微信审核 -> 发布小程序 -> 上架</p>

  </div>
</template>

<script>
  import { authMpAuthInfo, authMpAuthUrl, authMpUnbind } from '@/api/businessCard'
  import { Steps, Step, Link } from 'element-ui'
  import QRCode from 'qrcode'
  export default {
    name: 'weChat',
    components: { Step, Steps, Link },
    data() {
      return {
        authInfo: { mpAppId: ''},
        authUrl: '',
        authQrCode: '',
        active: 1
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        authMpAuthInfo().then(res => {
          this.authInfo = res.data;
          this.active = res.data.hasBindMp ? 3 : 1
        });
        authMpAuthUrl().then(res => {
          this.authUrl = res.data
          QRCode.toDataURL(this.authUrl).then(url => {
            this.authQrCode = url
          }).catch(err => {})
        })
      },
      authMiniApp() {
        this.active = 2
      },
      createWeChat() {
        window.open('https://mp.weixin.qq.com/cgi-bin/wx', '_blank')
      },
      unBind() {
        const self = this
        this.$antConfirm({
          title: '解绑',
          content: '解绑定后，小程序及其数据均被删除，无法恢复。\n 是否确定解绑？',
          width: '500px',
          okText: '解绑',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            authMpUnbind().then(res => {
              self.$message.success('已成功解绑');
              self.init()
            })
          },
          onCancel() {}
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
.we-chat{
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  margin: 12px;
  background: #fff;
  box-shadow: $cardShadow;
  border-radius: 4px;
  padding: 12px 12px 24px;
  position: relative;
  .we-chat-steps{
    width: 96%;
    margin: 24px 12px;
    .slot-step{
     margin: 0 12px;
    }
    ::v-deep{
      .el-step__icon{
        border: none;
        width: max-content;
        padding: 0 12px;
      }
    }
    .step-icon{
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      margin: 0 6px 0 12px;
      color: #C0C4CC;
      border:1px solid #C0C4CC;
      font-weight: normal;
    }
    .is-finish {
      .step-icon{
        background: #409EFF;
        color: #fff;
        border-color: #409EFF;
      }
    }
  }
  .register{
    text-align: center;
    .required-mini{
      font-size: 20px;
      color: #3f536e;
      margin: 48px 0 12px;
      letter-spacing: 1px;
      font-weight: bold;
    }
    .des{
      font-size: 14px;
      color: $title2;
      font-weight: normal;
    }
    .auth-link{
      vertical-align: unset;
      margin-left: 4px;
      ::v-deep .el-link--inner{
        cursor: pointer;
      }
    }
    .create-btn{
      margin-top: 48px;
    }
  }
  .auth-content{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 48px 0;
    .auth-item{
      text-align: center;
      .auth-code{
        width: 208px;
        height: 208px;
      }
      .auth-phone{
        width: 150px;
        height: auto;
      }
      p{
        margin-top: 12px;
        font-size: 14px;
        color: $title1;
        font-weight: 600;
      }
    }
  }
  .auth-success{
    text-align: center;
    padding-top: 48px;
    .we-chat-icon{
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
    .title{
      font-size: 16px;
      color: $title1;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      margin-bottom: 24px;
    }
    .global-flex{
      width: 320px;
      padding: 12px 0;
      border-top: 1px $themeBg solid;
      margin: 0 auto;
      .global-flex-label{
        width: 100px;
        text-align: left;
        font-weight: 600;
      }
    }
  }
}
  .un-bind{
    margin-left: 24px;
    border-bottom: 1px #F56C6C solid;
    cursor: pointer;
    color: #F56C6C;
  }
  .qrCode{
    width: 180px;
    height: 180px;
    border-radius: 4px;
    margin-top: 24px;
  }
  .no-pub{
    font-size: 12px;
    margin-top: 12px;
    color: #6b6b6b;
  }
  .pub{
    font-size: 14px;
    margin-top: 12px;
    font-weight: bold;
    color: #67C23A;
  }
  .public-des{
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    width: max-content;
    background: #f5f5f5;
    border-radius: 6px;
    padding: 12px;
    color: #6b6b6b;
  }
</style>
